import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import 'normalize.css/normalize.css'
import 'hover.css'

import './icons'

import slideIn from './utils/vSlideIn'
Vue.directive('slide-in', slideIn)

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
