<template>
  <div class="home">
    <div class="header">
      <div class="head">
        <img src="@/assets/logo@2x.png" />
      </div>
      <div class="head fixed" :class="{'active': active}">
        <img src="@/assets/logo@2x.png" />
      </div>
      <div class="title">
        <div v-slide-in class="left-warp">
          <div class="tit1">Living services</div>
          <div class="tit2">让东南亚的生活更快捷</div>
          <div class="tit3">做东南亚更受欢迎的生活服务平台</div>
        </div>
        <img v-slide-in src="@/assets/banner.gif" class="banner-img" />
      </div>
    </div>
    <div class="main-content w1000">
      <div v-slide-in class="company-recommend" :class="{'active': active}">
        <img src="@/assets/company@2x.png" />
        <div class="info">
          <div class="title">公司介绍</div>
          <div class="detail">湖南八万里科技有限公司于2023年6月6日成立，公司主要面向东南亚用户提供本地生活服务，公司主要产品服务包含:机票、酒店、外卖、充值。目前平台在海外已经吸收了30万+的海外用户，公司秉承为海外用户提供更加高效便捷的生活，不断提高公司产品的饱和度。公司在资源整合上不断加深资源开拓的深度和广度，加强了于湖南惜时网络科技有限公司、湖南艾珀因特信息科技有限公司的战略合作，提高公司在技术、运营的实力，让我们海外产品走的更远，不断提高产品的竞争力。</div>
        </div>
      </div>
      <div v-slide-in class="business-recommend">
        <div class="info">
          <div class="title">业务介绍</div>
          <div class="detail">在线机票：东南亚国内国际航班线上预定,出行快人一步!客服24小时在线，订票更便捷;平台资金监管交易更安全;打造东南亚出行新方式!</div>
          <div class="detail">酒店预定：为东南亚用户提供多语言线上服务，售前更贴心，售后更放心!我们将不断提升服务质量，丰富酒店数量，提升居住体验!</div>
          <div class="detail">外卖跑腿：网罗各国美食，中餐西餐,日韩料理、本土美食、甜蔬,品饮料、蛋糕烘焙、火锅烧烤、早餐,下午茶、宵夜，鲜花、果、生鲜、超市..专业骑手团队为您开跑!</div>
          <div class="detail">手机充值：提供东南亚等14国话费、流量、语音充值服务，为出国华人及本地用户提供安全便捷的充值服务。智能缴费，快人一步!</div>
        </div>
        <div class="items">
          <div
            class="item hvr-pulse-shrink"
            v-for="item in list"
            :key="item.en"
            :class="{'acitve': hoveName === item.name}"
            @mouseenter="() => handleEnter(item)"
            @mouseleave="() => handleEnter()"
          >
            <img :src="hoveName === item.name ? item.logo_a : item.logo" />
            <div class="name">{{ item.name }}</div>
            <div class="en">{{ item.en }}</div>
          </div>
        </div>
      </div>
      <div class="cooperation">
        <div v-slide-in class="title">
          <div class="en">BUSINESS COOPERATION</div>
          <div class="name">商务合作</div>
        </div>
        <div v-slide-in class="items">
          <div class="item" v-for="item in coopList" :key="item.name">
            <div class="title">{{ item.title }}</div>
            <div class="detial">{{ item.detial }}</div>
            <div class="bot">产品介绍</div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact">
      <div class="w1000">
        <div v-slide-in>
          <div class="title">联系我们</div>
          <div class="en">CONTACT US</div>
        </div>
        <div v-slide-in class="card">
          <div class="qr-code">
            <img src="@/assets/qr_code@2x.png" />
            <div class="info">微信扫一扫添加</div>
          </div>
          <div class="addr">
            <div class="info">客服在线上午8:00点至晚上22:00点</div>
            <div class="item" v-for="item in addrList" :key="item.icon">
              <div class="label">
                <svg-icon :icon="item.icon" />{{ item.label }}：
              </div>
              <div class="message">{{ item.info }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      @2023 湖南八万里科技有限公司. All rights reserved. <a href="https://beian.miit.gov.cn">湘ICP备2023021284号-1</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home',
  data() {
    return {
      list: [
        {
          name: '在线机票',
          en: 'ONLINE TICKET',
          logo: require('@/assets/aeroplane@2x.png'),
          logo_a: require('@/assets/aeroplane_a@2x.png')
        },
        {
          name: '酒店预定',
          en: 'HOTEL RESERVATION',
          logo: require('@/assets/hotel@2x.png'),
          logo_a: require('@/assets/hotel_a@2x.png')
        },

        {
          name: '外卖跑腿',
          en: 'TAKE-OUT ERRANDS',
          logo: require('@/assets/takeout@2x.png'),
          logo_a: require('@/assets/takeout_a@2x.png')
        },
        {
          name: '手机充值',
          en: 'MOBILE PHONE RECHARGE',
          logo: require('@/assets/recharge@2x.png'),
          logo_a: require('@/assets/recharge_a@2x.png')
        }
      ],
      coopList: [
        {
          title: '充值代理商',
          detial: '覆盖东南亚14个国家手机话费/流量充值，水电、网费缴纳业务：多渠道代理，一站式服务。提供方便、快捷、安全的便民服务！如您有相关资源及合作意向，请联系我们:bawanlikefu(中英)，laowotongkefu(中老)'
        },
        {
          title: '机票供应商',
          detial: '为老挝、束埔寨、越南、菲律宾、泰国等东南亚用户提供更安全便捷的订票服务。实现多语言展示、多币种支付，多渠道销售，目前可通过“八万里”APP“八万里机票”小程序、FB/TIKTOK等方式预定。合作商:@柬埔赛天空吴哥、束埔寨航空、洞湄航空、LaoAirlines、Lao Sky way、HONGYUNBUSINESS SOLE CO,LTD、sky-surfingBusinessTravel co.,ltd Hong Ngoc HaTravel DATACOM VIETNAM TECHNOLOGY CO.LTD如您有相关资源及合作意向，请联系我们：bawanlikefu(中英)856-2052453333'
        },
        {
          title: '外卖商家入驻',
          detial: '加入八万里平台，纵享百万用户基础：低风险低佣金、高保障！我们提供完整的商家、高收益管理系统、专业的骑手团队、24小时客服服务，期待您的加盟！请联系我们：856-2052457777'
        },
        {
          title: '酒店供应商',
          detial: '目前可通过“八万里”APP以及”八万里酒店”小程序进行酒店线上预定，已接入高中低不同价格、各具特色的当地酒店&星级酒店。平台已经与东昌酒店，铂骊酒店，盛大酒店，万象广场酒店等众多知名品牌达成协议。如您有相关资源及合作意向，请联系我们：856-2052452222'
        }
      ],
      addrList: [
        {
          label: '公司地址',
          icon: 'addr',
          info: '湖南省长沙市岳区银盘岭街道银杉路31号绿地时代广场5栋2013房-1005号'
        },
        {
          label: '联系电话',
          icon: 'tel',
          info: '189-00742937'
        },
        {
          label: '联系邮箱',
          icon: 'email',
          info: 'bawanli2022@126.com'
        }
      ],
      hoveName: '',
      active: false
    }
  },
  mounted() {
    window.addEventListener('DOMMouseScroll', this.scrollHandler, false)
	  window.onmousewheel = document.onmousewheel = this.scrollHandler
    this.scrollHandler()
  },
  beforeDestroy() {
    window.removeEventListener('DOMMouseScroll', this.scrollHandler)
  },
  methods: {
    handleEnter(item) {
      this.hoveName = item?.name || ''
    },
    scrollHandler() {
      setTimeout(() => {
        this.active = document.body.scrollTop > 60
      }, 50)
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  line-height: 1;
  .w1000 {
    width: 1200px;
  }
  .en {
    font-size: 12px;
    font-family: Adobe Heiti Std;
    transform: scale(0.8);
  }
  .header {
    height: 100vh;
    background: url('@/assets/banner_bg@2x.png') no-repeat center center;
    background-size: cover;
    min-height: 566px;
    position: relative;
    .head {
      width: 100%;
      height: 58px;
      background-color: transparent;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      z-index: 999;
      &.fixed {
        position: fixed;
        opacity: 0;
      }
      &.active {
        background-color: #1039AD;
        opacity: 1;
        animation: all .5s ease;
      }
      img {
        margin-left: 350px;
        width: 240px;
      }
    }
    .title {
      width: 1200px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 22;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left-warp {
        color: #FFFFFF;
        font-weight: normal;
        font-family: Adobe Heiti Std;
        white-space: nowrap;
        .tit1 {
          font-size: 30px;
          position: relative;
          margin-bottom: 18px;
          &::before {
            content: '';
            width: 30px;
            height: 4px;
            background: #FFFFFF;
            border-radius: 1px;
            position: absolute;
            top: -16px;
            left: 0;
          }
        }
        .tit2 {
          font-size: 40px;
          width: fit-content;
          padding: 5px 22px 8px 7px;
          background-color: #0E35CC;
          margin-bottom: 20px;
        }
        .tit3 {
          font-size: 38px;
        }
      }
      .banner-img {
        height: 640px;
        transform: translate(100px, 0);
      }
    }
  }
  .main-content {
    margin: 0 auto;
    .company-recommend {
      position: relative;
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 80px;
      &.active {
        margin-top: -80px;
      }
      img {
        width: 500px;
        margin-right: 120px;
      }
      .info {
        padding: 26px 80px 0 0;
        .title {
          font-size: 30px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #000000;
          line-height: 30px;
          margin-bottom: 16px;
        }
        .detail {
          width: 500px;
          font-size: 16px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #535353;
          line-height: 24px;
        }
      }
    }
    .business-recommend {
      height: 360px;
      width: 100%;
      border-radius: 2px;
      display: flex;
      align-items: center;
      margin-bottom: 120px;
      .info {
        flex: 1;
        background-color: #E9EAEF;
        height: 100%;
        padding: 0 18px;
        .title {
          font-size: 28px;
          font-family: Adobe Heiti Std;
          color: #1A1A1A;
          line-height: 38px;
          margin: 33px 0 18px;
        }
        .detail {
          font-size: 14px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #535353;
          line-height: 26px;
        }
      }
      .items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border: 1px solid #E7E7E7;
        width: 500px;
        height: calc(100% - 2px);
        position: relative;
        &::before {
          content: '';
          height: 1px;
          width: 100%;
          background-color: #E7E7E7;
          position: absolute;
          top: calc(50% - 1px);
          left: 0;
        }
        &::after {
          content: '';
          height: 100%;
          width: 1px;
          background-color: #E7E7E7;
          position: absolute;
          top: 0;
          left: calc(50% - 1px);
        }
        .item {
          height: calc(50% - 2px);
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          cursor: pointer;
          color: #535353;
          &.acitve {
            background-color: #4680FD;
            color: #fff;
          }
          img {
            height: 25px;
            margin-bottom: 14px;
          }
          .name {
            font-size: 14px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            line-height: 16px;
            margin-bottom: 4px;
          }
        }
      }
    }
    .cooperation {
      padding-bottom: 100px;
      .title {
        text-align: center;
        .en {
          font-weight: 500;
          font-size: 14px;
          padding-bottom: 6px;
        }
        .name {
          font-weight: 500;
          font-size: 24px;
          font-family: Adobe Heiti Std;
          color: #282828;
          line-height: 26px;
        }
      }
      .items {
        padding-top: 60px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .item {
          position: relative;
          width: 22.5%;
          &:not(:last-child)::after {
            content: '';
            height: 80%;
            width: 1px;
            background-color: #E8E8E8;
            position: absolute;
            right: -20px;
            top: 0;
          }
          .title {
            font-size: 18px;
            font-weight: 500;
            font-family: Adobe Heiti Std;
            color: #1A1A1A;
            line-height: 32px;
            padding-bottom: 32px;
            position: relative;
            &::after {
              content: '';
              height: 1px;
              width: 85%;
              background-color: #DEDEDE;
              position: absolute;
              left: 50%;
              bottom: 12px;
              transform: translate(-50%, 0);
            }
          }
          .detial {
            font-size: 14px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #646464;
            line-height: 22px;
            width: 90%;
            margin: 0 auto 24px;
          }
          .bot {
            width: 100px;
            text-align: center;
            background: linear-gradient(90deg, #539DFE, #3E66FC);
            margin: 0 auto;
            font-size: 12px;
            font-family: Adobe Heiti Std;
            color: #FFFFFF;
            line-height: 24px;
            border-radius: 20px;
            transform: scale(.95);
          }
        }
      }
    }
  }
  .contact {
    height: 600px;
    width: 100%;
    background: url('@/assets/footer-bg@2x.png') no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    & > div {
      margin: 0 auto;
    }
    .title {
      padding-top: 42px;
      font-size: 24px;
      font-weight: 500;
      font-family: Adobe Heiti Std;
      color: #2D2D2D;
      line-height: 27px;
      margin-bottom: 6px;
    }
    .en {
      font-weight: 500;
      font-size: 14px;
      transform: translate(-120px, 0) scale(.8);
      margin-bottom: 20px;
    }
    .card {
      width: 100%;
      height: 340px;
      display: flex;
      align-items: center;
      background: #FFFFFF;
      box-shadow: 0px 2px 3px 0px rgba(169,169,169,0.75);
      border-radius: 6px;
      overflow: hidden;
      & > div {
        flex: 1;
      }
      .qr-code {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img {
          height: 164px;
          margin-bottom: 24px;
        }
        .info {
          font-size: 14px;
          font-family: Adobe Heiti Std;
          color: #030303;
        }
      }
      .addr {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        background: url('@/assets/addr_bg@2x.png');
        background-size: cover;
        color: #fff;
        .info {
          font-size: 16px;
          font-family: Adobe Heiti Std;
          margin-bottom: 16px;
          padding-left: 40px;
        }
        .item {
          display: flex;
          align-items: flex-start;
          font-size: 14px;
          line-height: 24px;
          padding-left: 40px;
          font-family: Adobe Heiti Std;
          &:not(:last-child) {
            margin-bottom: 12px;
          }
          .label {
            display: flex;
            align-items: center;
            .svg-icon {
              margin-right: 8px;
            }
          }
          .message {
            width: 240px;
          }
        }
      }
    }
  }
  .footer {
    line-height: 90px;
    text-align: center;
    background-color: #00287E;
    font-size: 12px;
    font-family: Adobe Heiti Std;
    color: #FFFFFF;
    a {
      color: #fff;
      text-decoration: none;
      &:hover {
        text-decoration:underline;
      }
    }
  }
}
</style>
