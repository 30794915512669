const DISTANCE = 150
const DURATION = 1000

const animationMap = new WeakMap()
const ob = new IntersectionObserver((entries) => {
  for (const entry of entries) {
    if (entry.isIntersecting) {
      const animation = animationMap.get(entry.target)
      animation.play()
      ob.unobserve(entry.target)
    }
  }
})

const isBelowViewport = (el) => {
  const rect = el.getBoundingClientRect()
  return rect.top >= 200
}

export default {
  inserted(el, binding) {
    if (!isBelowViewport(el)) return
    const { value } = binding
    const animation = el.animate(
      [
        {
          transform: `translateY(${value || DISTANCE}px)`,
          opacity: 0
        },
        {
          transform: `translateY(0)`,
          opacity: 1
        },
      ],
      {
        duration: DURATION,
        easing: 'ease'
      }
    )
    animation.pause()
    animationMap.set(el, animation)
    ob.observe(el)
  },
  unbind(el) {
    ob.unobserve(el)
  }
}
